import HeaderTop from './HeaderTop';
import HeaderBottom from './HeaderBottom';
import 'assets/scss/_header.scss';

const Header = () => {
    return (<>
        <header className="main__header">
            <div className="header__inner">
                <HeaderTop />
                <HeaderBottom />
            </div>
        </header>
    </>);
};

export default Header;