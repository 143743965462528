import axios from "axios";
import { toast } from "react-toastify";
import { getLocalSession, getUserSession } from "auth/index.js";
import { ACCESS_TOKEN, API_BASE_PATH } from "common/config";

export const publicInstance = axios.create({
    baseURL: API_BASE_PATH,
});

// with access token
publicInstance.interceptors.request.use(
    (request) => {
        request.headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            // "Accept-Encoding": "gzip, deflate, br",
            "Access-Control-Allow-Origin": "*",
        };
        return request;
    },
    (error) => Promise.reject(error)
);
// failure handling
publicInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (401 === error.response.status) {
            toast.error("Unauthorized");
        }

        Promise.reject(error);
    }
);