import { setLocalSession } from "auth";
import { AppRoutes } from "AppRoutes";

const App = () => {
  if (process.env.NODE_ENV !== "development") {
    console.log =
      console.error =
      console.info =
      console.debug =
      console.warn =
      function () { };
  }
  setLocalSession();
  return <AppRoutes />;
};

export default App;