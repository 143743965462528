import React from "react";
import coloabImage from 'assets/images/menu-img-01.webp';
import collectionImage from 'assets/images/menu-img-02.webp';
import menuImage3 from 'assets/images/menu-img-03.webp';
import collectionImage1 from 'assets/images/collection-img1.png';
import collectionImage2 from 'assets/images/collection-img2.png';
import collectionImage3 from 'assets/images/collection-img3.png';
import collectionImage4 from 'assets/images/collection-img4.png';
import btnIcon from 'assets/images/btn-icon.svg';

const HeaderBottom = () => {
    return (<>
        <div className="header-bottom">
            <div className="container-fluid px-7">
                <div className="row align-items-center justify-content-between">
                    <div className="col-6 col-md-10">
                        <div className="header-bottom-start">
                            <div
                                className="tp-header-bottom tp-header-bottom-border d-none d-lg-block"
                            >
                                <div className="tp-mega-menu-wrapper p-relative">
                                    <div className="row align-items-center">
                                        <div className="col-xl-12 col-lg-12">
                                            <div className="main-menu menu-style-1">
                                                <nav className="tp-main-menu-content">
                                                    <ul>
                                                        <li className="has-dropdown has-mega-menu">
                                                            <a href="shop.html">BRACELETS</a>
                                                            <div
                                                                className="shop-mega-menu tp-submenu tp-mega-menu"
                                                            >
                                                                <div className="row">
                                                                    <div className="col-lg-3">
                                                                        <div className="shop-mega-menu-list">
                                                                            <a
                                                                                href="#"
                                                                                className="shop-mega-menu-title"
                                                                            >Shop by category</a
                                                                            >
                                                                            <ul>
                                                                                <li>
                                                                                    <a href="#"
                                                                                    >The zaiba collection</a
                                                                                    >
                                                                                </li>
                                                                                <li><a href="#">Studs</a></li>
                                                                                <li><a href="#">Pearls</a></li>
                                                                                <li>
                                                                                    <a href="#">Petite Collection</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="#"
                                                                                    >The Classic Collection</a
                                                                                    >
                                                                                </li>
                                                                                <li>
                                                                                    <a href="#"
                                                                                    >Affordable luxury aed 135 – aed
                                                                                        159</a
                                                                                    >
                                                                                </li>
                                                                                <li>
                                                                                    <a href="#"
                                                                                    >The sofia collection</a
                                                                                    >
                                                                                </li>
                                                                                <li>
                                                                                    <a href="#"
                                                                                    >The miranda collection</a
                                                                                    >
                                                                                </li>
                                                                                <li>
                                                                                    <a href="#"
                                                                                    >The coco collection</a
                                                                                    >
                                                                                </li>
                                                                                <li><a href="#">Star sign</a></li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3">
                                                                        <div className="shop-mega-menu-list">
                                                                            <a
                                                                                href="#"
                                                                                className="shop-mega-menu-title"
                                                                            >Trending</a
                                                                            >
                                                                            <ul>
                                                                                <li><a href="#">Bestsellers</a></li>
                                                                                <li>
                                                                                    <a href="#">New in this Week</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="#">Most Wishlisted</a>
                                                                                </li>
                                                                                <li>
                                                                                    <a href="#">Exclusive on ZAIBA</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-2">
                                                                        <a href="#" className="shop-mega-menu-title"
                                                                        >Collab</a
                                                                        >
                                                                        <div className="shop-mega-menu-img">
                                                                            <img
                                                                                src={coloabImage}
                                                                                className="img-fluid"
                                                                                alt=""
                                                                            />
                                                                            <div className="shop-mega-menu-btn">
                                                                                <a href="#" className="btn btn-view"
                                                                                >VIEW COLLECTION</a
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2">
                                                                        <a href="#" className="shop-mega-menu-title"
                                                                        >&nbsp;
                                                                        </a>
                                                                        <div className="shop-mega-menu-img">
                                                                            <img
                                                                                src={collectionImage}
                                                                                alt=""
                                                                            />
                                                                            <div className="shop-mega-menu-btn">
                                                                                <a href="#" className="btn btn-view"
                                                                                >VIEW COLLECTION</a
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-2">
                                                                        <a href="#" className="shop-mega-menu-title"
                                                                        >&nbsp;
                                                                        </a>
                                                                        <div className="shop-mega-menu-img">
                                                                            <img
                                                                                src={menuImage3}
                                                                                alt=""
                                                                            />
                                                                            <div className="shop-mega-menu-btn">
                                                                                <a href="#" className="btn btn-view"
                                                                                >VIEW COLLECTION</a
                                                                                >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="has-dropdown has-mega-menu">
                                                            <a href="#">COLLECTIONS</a>
                                                            <div
                                                                className="home-menu tp-submenu tp-mega-menu"
                                                            >
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <div className="home-menu-item">
                                                                            <a href="#">
                                                                                <div className="home-menu-thumb">
                                                                                    <img
                                                                                        src={collectionImage1}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="home-menu-content">
                                                                                    <h5 className="home-menu-title">
                                                                                        <img
                                                                                            src={btnIcon}
                                                                                            alt=""
                                                                                        />
                                                                                        MADE OF WHITE GOLD
                                                                                    </h5>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="home-menu-item">
                                                                            <a href="#">
                                                                                <div
                                                                                    className="home-menu-thumb p-relative fix"
                                                                                >
                                                                                    <img
                                                                                        src={collectionImage2}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="home-menu-content">
                                                                                    <h5 className="home-menu-title">
                                                                                        <img
                                                                                            src={btnIcon}
                                                                                            alt=""
                                                                                        />
                                                                                        MADE OF WHITE GOLD
                                                                                    </h5>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="home-menu-item">
                                                                            <a href="#">
                                                                                <div
                                                                                    className="home-menu-thumb p-relative fix"
                                                                                >
                                                                                    <img
                                                                                        src={collectionImage3}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="home-menu-content">
                                                                                    <h5 className="home-menu-title">
                                                                                        <img
                                                                                            src={btnIcon}
                                                                                            alt=""
                                                                                        />
                                                                                        MADE OF WHITE GOLD
                                                                                    </h5>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col">
                                                                        <div className="home-menu-item">
                                                                            <a href="#">
                                                                                <div
                                                                                    className="home-menu-thumb p-relative fix"
                                                                                >
                                                                                    <img
                                                                                        src={collectionImage4}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                                <div className="home-menu-content">
                                                                                    <h5 className="home-menu-title">
                                                                                        <img
                                                                                            src={btnIcon}
                                                                                            alt=""
                                                                                        />
                                                                                        MADE OF WHITE GOLD
                                                                                    </h5>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                        <li>
                                                            <a href="product-listing.html">NECKLACES</a>
                                                        </li>
                                                        <li>
                                                            <a href="product-listing.html">RINGS</a>
                                                        </li>
                                                        <li>
                                                            <a href="product-listing.html">RINGS</a>
                                                        </li>
                                                        <li>
                                                            <a href="product-listing.html">PENDANTS</a>
                                                        </li>
                                                        <li>
                                                            <a href="product-listing.html">EARRINGS</a>
                                                        </li>
                                                        <li>
                                                            <a href="product-listing.html">CHAINS</a>
                                                        </li>
                                                        <li>
                                                            <a href="product-listing.html">DIAMOND</a>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li>
                                                            <a href="#">CATALOG</a>
                                                        </li>
                                                        <li>
                                                            <a href="about.html">ABOUT US</a>
                                                        </li>
                                                        <li>
                                                            <a href="find-store.html">FIND A STORE</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tp-header-action-item d-lg-none">
                                <button
                                    type="button"
                                    className="tp-header-action-btn tp-offcanvas-open-btn"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="30"
                                        height="16"
                                        viewBox="0 0 30 16"
                                    >
                                        <rect
                                            x="10"
                                            width="20"
                                            height="2"
                                            fill="currentColor"
                                        />
                                        <rect
                                            x="5"
                                            y="7"
                                            width="25"
                                            height="2"
                                            fill="currentColor"
                                        />
                                        <rect
                                            x="10"
                                            y="14"
                                            width="20"
                                            height="2"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 w-auto">
                        <div className="header-bottom-end">
                            <div className="today-rate">
                                <span>TODAY RATE 24K </span>
                                <h5>AED 242.25</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default HeaderBottom;