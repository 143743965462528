import React from 'react';
import { Outlet } from 'react-router-dom';

// import '../assets/scss/_mixins.scss';
import 'assets/scss/_fontawesome.scss';
import 'assets/scss/_fonts.scss';
import 'assets/scss/_bootstrap.min.scss';
import 'assets/scss/_aos.scss';
import 'assets/scss/_animate.min.scss';
import 'assets/scss/_normal.scss';

import Header from 'components/common/header';
import Footer from 'components/common/footer';



function SiteLayout() {
    return (
        <div className="layout">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

export default SiteLayout;