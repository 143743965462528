import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Layouts
import SiteLayout from 'layouts/SiteLayout';

// Screens
const Home = React.lazy(() => import("screens/Home"));
const Login = React.lazy(() => import("screens/auth/Login"));
const Register = React.lazy(() => import("screens/auth/Register"));


const Test = () => {
    return (
        <>
            Hellooooooooo
        </>
    )
}

const routeList = [
    {
        layout: <SiteLayout />,
        innerRoutes: [
            {
                path: "/",
                component: <Home />,
                exact: true,
                private: false,
            },
            {
                path: "/test",
                component: <Test />,
                exact: true,
                private: false,
            },
            {
                path: "/login",
                component: <Login />,
                exact: true,
                private: false,
            },
            {
                path: "/register",
                component: <Register />,
                exact: true,
                private: false,
            }
        ]
    }
];


export const AppRoutes = () => {
    const loader = (
        <div
            style={{
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <span style={{
                height: "100px",
                width: "100px",
                backgroundColor: "#000"
            }} className="spinner-border" />
        </div>
    );

    return (
        <Router >
            <Suspense fallback={loader}>
                <Routes>
                    <Route
                        path="/"
                        element={<SiteLayout />}
                    >
                        <Route
                            path="/"
                            element={<Home />}
                            exact='true'
                        />
                        <Route
                            path="login"
                            element={<Login />}
                            exact='true'
                        />
                        <Route
                            path="register"
                            element={<Register />}
                            exact='true'
                        />
                    </Route>
                </Routes>
            </Suspense>

        </Router >
    );
};