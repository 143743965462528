import React from 'react';
import 'assets/scss/_footer.scss';

import facebookIcon from 'assets/images/facebook.svg';
import instagramIcon from 'assets/images/instagram.svg';
import tiktokIcon from 'assets/images/tiktok.svg';
import twitterIcon from 'assets/images/twitter.svg';
import youtubeIcon from 'assets/images/youtube.svg';

const Footer = () => {
    return (<>
        <footer className="footer">
            <div className="social-inner">
                <div className="container-fluid px-7">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-3">
                            <h3 className="sub-title">Social</h3>
                            <p>Stay current with updates from our social channels.</p>
                        </div>
                        <div className="col-md-7">
                            <div className="social-liks">
                                <ul>
                                    <li>
                                        <a href="#">
                                            <img src={facebookIcon} alt="Facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={instagramIcon} alt="Instagram" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={tiktokIcon} alt="tiktok" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={twitterIcon} alt="twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src={youtubeIcon} alt="youtube" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-inner">
                <div className="footer-start">
                    <div className="container-fluid px-7">
                        <div className="row">
                            <div className="col-6 col-md-3">
                                <div className="footer-links">
                                    <h4>THE SHOP</h4>
                                    <ul>
                                        <li>
                                            <a href="#">Rings</a>
                                        </li>
                                        <li>
                                            <a href="#">Pendants</a>
                                        </li>
                                        <li>
                                            <a href="#">Bracelets</a>
                                        </li>
                                        <li>
                                            <a href="#">Earrings</a>
                                        </li>
                                        <li>
                                            <a href="#">Watches</a>
                                        </li>
                                        <li>
                                            <a href="#">Bangles</a>
                                        </li>
                                        <li>
                                            <a href="#">Necklaces</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <div className="footer-links">
                                    <h4>MY ACCOUNT</h4>
                                    <ul>
                                        <li>
                                            <a href="#">My account</a>
                                        </li>
                                        <li>
                                            <a href="#">Wishlist</a>
                                        </li>
                                        <li>
                                            <a href="#">Cart</a>
                                        </li>
                                        <li>
                                            <a href="#">Checkout</a>
                                        </li>
                                        <li>
                                            <a href="#">Register Now</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <div className="footer-links">
                                    <h4>INFORMATION</h4>
                                    <ul>
                                        <li>
                                            <a href="#">About Us</a>
                                        </li>
                                        <li>
                                            <a href="#">Our Blog</a>
                                        </li>
                                        <li>
                                            <a href="#">FAQ</a>
                                        </li>
                                        <li>
                                            <a href="#">Contacts</a>
                                        </li>
                                        <li>
                                            <a href="#">Terms & Conditions</a>
                                        </li>
                                        <li>
                                            <a href="#">Refund and Returns Policy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <div className="footer-links">
                                    <h4>OUR CONTACTS</h4>
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="bi bi-geo-alt me-2"></i>Deira & Al Qouz ,
                                                Dubai, United Arab Emirates</a
                                            >
                                        </li>
                                        <li>
                                            <a href="#">
                                                <i className="bi bi-telephone me-2"></i> +971 4 226 4212</a
                                            >
                                        </li>
                                        <li>
                                            <a href="#"
                                            ><i className="bi bi-envelope me-2"></i>
                                                info@zaiba-jew.com</a
                                            >
                                        </li>
                                        <li>
                                            <a href="#"
                                            ><i className="bi bi-clock me-2"></i> Mon - Fri: 10:00 -
                                                18:00</a
                                            >
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-end">
                    <div className="container-fluid px-7">
                        <div className="row justify-content-between">
                            <div className="col-md-4">
                                <p>Terms & Conditions | Privacy Policy | Cookie Policy</p>
                            </div>
                            <div className="col-md-4 text-center">
                                <p>Copyright © 2023 Zaiba Jewels. All rights reserved.</p>
                            </div>
                            <div className="col-md-4 text-end">
                                <img
                                    src="assets/images/payment-option.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <div
            className="offcanvas offcanvas-end zb-slide-cart"
            tabIndex="-1"
            id="offcanvasRight"
            aria-labelledby="offcanvasRightLabel"
        >
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasRightLabel">Shopping Cart</h5>
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                ></button>
            </div>
            <div className="offcanvas-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="zb-checkout-summary">
                            <div className="zb-order-review-warpper">
                                <div className="zb-order-review-list">
                                    <div className="zb-order-review-img">
                                        <img
                                            src="assets/images/image 13.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="zb-order-review-info">
                                        <h3>Dome Majesty Malachite Diamond Ring</h3>
                                        <ul>
                                            <li>Size: 19</li>
                                            <li>Price Breakup:1172</li>
                                        </ul>
                                        <div className="zb-order-review-price">
                                            <h4>AED180,00</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="zb-order-review-list">
                                    <div className="zb-order-review-img">
                                        <img
                                            src="assets/images/image 13.png"
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </div>
                                    <div className="zb-order-review-info">
                                        <h3>Dome Majesty Malachite Diamond Ring</h3>
                                        <ul>
                                            <li>Size: 19</li>
                                            <li>Price Breakup:1172</li>
                                        </ul>
                                        <div className="zb-order-review-price">
                                            <h4>AED180,00</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="zb-order-review-subtotal">
                                <div className="zb-cart-summary-content px-0">
                                    <div className="zb-product-amount-list">
                                        <ul>
                                            <li>
                                                <span>Subtotal(2 item)</span>
                                                <span>AED 540.00</span>
                                            </li>
                                        </ul>
                                    </div>

                                    <a href="cart.html" className="btn btn-login w-100"> View Cart</a>
                                    <a href="checkout.html" className="btn btn-checkout w-100 mt-3">
                                        Checkout</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default Footer;